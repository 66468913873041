import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { verticalSpace } from '@doltech/utils/lib/css-style';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';

const Container = styled.div`
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.gray20};
  padding: 20px;
  border-radius: 16px;
  ${verticalSpace(12)};

  display: grid;
  grid-auto-columns: minmax(0, 1fr);
`;

const IconWrapper = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 40px;
  background-color: ${colorsV2.purple10};

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${colorsV2.purple100};
    width: 20px;
    height: 20px;
  }
`;


interface CardHomeReasonProps {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
}

export const CardHomeReason = (props: CardHomeReasonProps) => {
  const { icon, title, description } = props;
  return (
    <Container className="card-home-reason">
      <IconWrapper>{icon}</IconWrapper>
      <ResponsiveTypography.Paragraph variant="semi-bold/20-28" color="gray180">
        {title}
      </ResponsiveTypography.Paragraph>
      <ResponsiveTypography.Paragraph variant="regular/14-20" color="gray160">
        {description}
      </ResponsiveTypography.Paragraph>
    </Container>
  );
};
