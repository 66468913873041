import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { flexGap, verticalSpace } from '@doltech/utils/lib/css-style';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { CardHomeReason } from './CardHomeReason';
import {
  IconSystemV2EssentialListDoListCheckmark,
} from '@doltech/icons/SystemV2/IconSystemV2EssentialListDoListCheckmark';
import { IconSystemV2SpecificStarCheck } from '@doltech/icons/SystemV2/IconSystemV2SpecificStarCheck';
import { IconSystemV2EssentialInprogress } from '@doltech/icons/SystemV2/IconSystemV2EssentialInprogress';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { IconsCourseContent } from '@doltech/icons/Course/IconsCourseContent';

const Container = styled.section`
  background-color: ${colorsV2.white100};
  display: flex;
  justify-content: center;
  padding: 40px 16px;

  ${fromScreen(768)} {
    padding: 120px 24px;
  }
`;

const Content = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${verticalSpace(36)};
`;

const TitleWrapper = styled.div`
  text-align: center;
  width: fit-content;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  ${flexGap([16, 16])};

  ${fromScreen(768)} {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
    ${flexGap([0, 0])}
  }
`;

export const SectionHomeReasons = () => {

  return (
    <Container>
      <Content>
        <TitleWrapper>
          <ResponsiveTypography.Title level={2} variant="bold/32-40" color="gray180">
            <ResponsiveTypography.Text color="purple100">
              4 lý do
            </ResponsiveTypography.Text>
            {' '}nên dùng DOL Grammar
          </ResponsiveTypography.Title>
        </TitleWrapper>
        <BodyWrapper>
          <CardHomeReason
            icon={<IconSystemV2EssentialListDoListCheckmark/>}
            title="Đầy đủ chủ đề ngữ pháp"
            description="Hệ thống lý thuyết ngữ pháp tiếng Anh của DOL Grammar được biên soạn đầy đủ và được phân loại tối ưu nhất giúp người dùng tra cứu và học tập một cách dễ dàng."
          />
          <CardHomeReason
            icon={<IconsCourseContent/>}
            title="Bài tập đi kèm với lý thuyết"
            description="Sau mỗi bài viết, DOL Grammar sẽ cung cấp các bài tập thực hành từ cơ bản đến nâng cao, giúp các bạn dễ dàng củng cố lại kiến thức đã đọc một cách hiệu quả hơn."
          />
          <CardHomeReason
            icon={<IconSystemV2SpecificStarCheck/>}
            title="Nội dung chất lượng, dễ hiểu"
            description="Các bài viết sẽ được DOL Grammar trình bày một cách trực quan và dễ hiểu, giúp cho việc học ngữ pháp tiếng Anh vốn khô khan trở nên dễ dàng và tối ưu hơn."
          />
          <CardHomeReason
            icon={<IconSystemV2EssentialInprogress/>}
            title="Tiến độ học tập được lưu lại"
            description="DOL Grammar có thể giúp bạn theo dõi tiến trình học tập của mình với tính năng lưu lại tiến độ học tập tiện lợi. Mọi bài tập và kết quả đều sẽ được lưu lại, giúp bạn tự tin hơn trên hành trình chinh phục ngữ pháp."
          />
        </BodyWrapper>
      </Content>
    </Container>
  );
};
