import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M8.244 3.332a1 1 0 01-.076 1.412l-3.006 2.7a1 1 0 01-1.336 0l-1.494-1.34a1 1 0 111.336-1.49l.826.742 2.338-2.1a1 1 0 011.412.076zM12 4.5a1 1 0 100 2h9a1 1 0 100-2h-9zm0 7a1 1 0 100 2h9a1 1 0 100-2h-9zm-3.832-.256a1 1 0 00-1.336-1.488l-2.338 2.1-.826-.741a1 1 0 00-1.336 1.488l1.494 1.341a1 1 0 001.336 0l3.006-2.7zM11 19.5a1 1 0 011-1h9a1 1 0 110 2h-9a1 1 0 01-1-1zm-6.854-.354a.5.5 0 11.708.707.5.5 0 01-.708-.707zm-1.414-1.414a2.5 2.5 0 113.536 3.535 2.5 2.5 0 01-3.536-3.535z" clipRule="evenodd" /></svg>;
});
export const IconSystemV2EssentialListDoListCheckmark = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));