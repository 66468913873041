import * as React from 'react';
import styled from 'styled-components';
import { SectionHomeHero } from './components/SectionHomeHero';
import { SectionHomeReasons } from './components/SectionHomeReasons';
import { SectionHomeTheory } from './components/SectionHomeTheory';
import { GrammarSectionDivider } from './components/GrammarSectionDivider';
import { useAuthorizeUser } from './hooks/useAuthorizeUser';
import { GrammarTemplateTypeEnum } from '../shared/constants';
import { getFinalUrlGrammar } from '../shared/utils';
import { TheoryCardBlogGrammar } from './components/TheoryCardBlogGrammar';
import { ExerciseCardBlogGrammar } from './components/ExerciseCardBlogGrammar';
import { SectionHomeTheoryAnimate } from './components/SectionHomeTheoryAnimate';
import { useFromScreens } from '@doltech/ui/lib/hooks/useDeviceQuery.hook';
import { isArray } from 'lodash';

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
`;

interface GrammarLandingHomePageNotAuthorProps {
  exerciseList?: any;
  topicList?: any;
  theoryList?: any;
  seoHomePage?: any;
  otherUrlInfo?: any;
}

export const GrammarLandingHomePageNotAuth = (props: GrammarLandingHomePageNotAuthorProps) => {
  const { exerciseList, topicList, theoryList, seoHomePage, otherUrlInfo } = props;
  const [, from1128] = useFromScreens([0, 1128]);
  const { onUserSignUp } = useAuthorizeUser();
  const exerciseUrl = otherUrlInfo?.find(item => item?.templateTypeId === GrammarTemplateTypeEnum.LANDING_GRAMMAR_EXERCISE)?.url;
  const theoryUrl = otherUrlInfo?.find(item => item?.templateTypeId === GrammarTemplateTypeEnum.LANDING_GRAMMAR_THEORY)?.url;

  const seoPageData = seoHomePage?.data?.[0];

  return (
    <Container>
      <SectionHomeHero
        title={seoPageData?.dol?.title}
        description={seoPageData?.dol?.description}
        topics={topicList?.data}
        onLogIn={onUserSignUp}
      />
      <Content>
        <SectionHomeReasons/>
        <GrammarSectionDivider/>
        {from1128 ?
          <>
            <SectionHomeTheoryAnimate
              title="Lý thuyết"
              description="Tìm hiểu các bài học lý thuyết đang được xem nhiều nhất."
              data={isArray(theoryList) ? theoryList : []}
              viewAllUrl={getFinalUrlGrammar(theoryUrl)}
              renderCardBlog={(grammar) => (
                <TheoryCardBlogGrammar
                  data={grammar}
                  isLanding
                />
              )}
            />
            <GrammarSectionDivider/>
            <SectionHomeTheoryAnimate
              title="Bài tập"
              description="Top các bài tập có nhiều lượt làm nhất tại DOL Grammar."
              data={isArray(exerciseList) ? exerciseList : []}
              viewAllUrl={getFinalUrlGrammar(exerciseUrl)}
              renderCardBlog={(grammar) => (
                <ExerciseCardBlogGrammar
                  data={grammar}
                  isLanding
                />
              )}
            />
          </> :
          <>
            <SectionHomeTheory
              title="Lý thuyết"
              description="Tìm hiểu các bài học lý thuyết đang được xem nhiều nhất."
              data={isArray(theoryList) ? theoryList : []}
              viewAllUrl={getFinalUrlGrammar(theoryUrl)}
              renderCardBlog={(grammar) => (
                <TheoryCardBlogGrammar
                  data={grammar}
                  isLanding
                />
              )}/>
            <GrammarSectionDivider/>
            <SectionHomeTheory
              title="Bài tập"
              description="Top các bài tập có nhiều lượt làm nhất tại DOL Grammar."
              data={isArray(exerciseList) ? exerciseList : []}
              viewAllUrl={getFinalUrlGrammar(exerciseUrl)}
              renderCardBlog={(grammar) => (
                <ExerciseCardBlogGrammar
                  data={grammar}
                  isLanding
                />
              )}
            />
          </>
        }

      </Content>
    </Container>
  );
};
