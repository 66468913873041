import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M12 2a1 1 0 100 2 8 8 0 018 8 7.87 7.87 0 01-.888 3.635 1 1 0 101.776.92A9.868 9.868 0 0022 12c0-5.523-4.477-10-10-10zM7.532 4.136a1 1 0 01-.223 1.396 8.084 8.084 0 00-.834.692 1 1 0 11-1.379-1.448c.327-.311.675-.6 1.04-.864a1 1 0 011.396.224zm10.299 14.79a1 1 0 01-.274 1.387A9.952 9.952 0 0112 22c-.672 0-1.33-.068-1.964-.195a1 1 0 11.393-1.96A7.98 7.98 0 0012 20a7.951 7.951 0 004.443-1.349 1 1 0 011.388.274zM8 11a1 1 0 100 2h4.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L12.586 11H8zm-3.362 4.11a1 1 0 10-1.842.78 10.003 10.003 0 002.925 3.888 1 1 0 101.257-1.556 8.004 8.004 0 01-2.34-3.111zm-.781-7.053a1 1 0 01.609 1.277 8.039 8.039 0 00-.442 2.053 1 1 0 11-1.993-.158c.07-.896.263-1.753.55-2.563a1 1 0 011.276-.609z" clipRule="evenodd" /></svg>;
});
export const IconSystemV2EssentialInprogress = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
