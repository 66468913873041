import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V15C14 15.5523 13.5523 16 13 16H3C2.44772 16 2 15.5523 2 15V3ZM3 0C1.34315 0 0 1.34315 0 3V15C0 16.6569 1.34315 18 3 18H7V19C7 20.6569 8.34315 22 10 22H19C20.6569 22 22 20.6569 22 19V8C22 6.34315 20.6569 5 19 5H16V3C16 1.34315 14.6569 0 13 0H3ZM16 7V15C16 16.6569 14.6569 18 13 18H9V19C9 19.5523 9.44772 20 10 20H19C19.5523 20 20 19.5523 20 19V8C20 7.44772 19.5523 7 19 7H16ZM5 8C4.44772 8 4 8.44771 4 9C4 9.55229 4.44772 10 5 10H8C8.55229 10 9 9.55229 9 9C9 8.44771 8.55229 8 8 8H5ZM5 12C4.44772 12 4 12.4477 4 13C4 13.5523 4.44772 14 5 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H5Z"
            fill="currentColor"/>
    </svg>
  )
});
export const IconsCourseContent = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
