import * as React from 'react';
import {
  GrammarLandingHomePageNotAuth
} from '@doltech/domains/landing-grammar/lib/home-page/GrammarLandingHomePageNotAuth';
import { GrammarTemplateProps } from './LandingGrammarTemplatePage';


const GrammarLandingHomePageNotAuthLayoutTemplatePage = (props: GrammarTemplateProps) => {
  return <GrammarLandingHomePageNotAuth {...props}/>;
};

export default GrammarLandingHomePageNotAuthLayoutTemplatePage;


