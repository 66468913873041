import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" d="M15.707 9.793a1 1 0 010 1.414l-3.75 3.75a1 1 0 01-1.414 0l-2.25-2.25a1 1 0 111.414-1.414l1.543 1.543 3.043-3.043a1 1 0 011.414 0z" /><path fill="currentColor" fillRule="evenodd" d="M10.545 2.103a2.057 2.057 0 012.91 0l1.853 1.852a.059.059 0 00.04.018h2.62a2.06 2.06 0 012.06 2.059v2.62c0 .014.005.029.017.04l1.852 1.853a2.057 2.057 0 010 2.91l-1.852 1.853a.059.059 0 00-.018.04v2.62a2.059 2.059 0 01-2.059 2.06h-2.62a.059.059 0 00-.04.017l-1.853 1.852a2.057 2.057 0 01-2.911 0l-1.852-1.852-.003-.004a.051.051 0 00-.039-.016H6.031a2.059 2.059 0 01-2.058-2.059v-2.62a.059.059 0 00-.018-.04l-1.852-1.853a2.057 2.057 0 010-2.911l1.87-1.87v-2.64c0-1.138.921-2.06 2.059-2.06h2.62a.054.054 0 00.04-.017l1.853-1.852zm1.414 1.414a.057.057 0 01.081 0l1.854 1.853c.385.385.908.603 1.455.603h2.62c.032 0 .058.026.058.059v2.62c0 .546.218 1.07.603 1.455l1.853 1.852a.057.057 0 010 .081l-1.853 1.854a2.059 2.059 0 00-.603 1.455v2.62a.059.059 0 01-.059.058h-2.62a2.06 2.06 0 00-1.455.603l-1.852 1.853a.057.057 0 01-.081 0l-1.85-1.849-.002-.002a2.052 2.052 0 00-1.458-.607H6.031a.059.059 0 01-.058-.059v-2.62a2.06 2.06 0 00-.603-1.455L3.517 12.04a.057.057 0 010-.081l1.872-1.873c.374-.374.584-.88.584-1.41V6.031c0-.032.026-.058.059-.058h2.62a2.06 2.06 0 001.455-.603l1.852-1.853z" clipRule="evenodd" /></svg>;
});
export const IconSystemV2SpecificStarCheck = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
